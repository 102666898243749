// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  header, footer {
    background-color: #f8f9fa;
    padding: 10px;
    text-align: center;
  }
  
  .main {
    padding: 20px;
  }
  
  .buttons {
    margin-bottom: 20px;
  }
  
  button {
    margin-right: 10px;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  .selected-btn{
    background-color: #0056b3;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .table-section {
    margin-top: 20px;
  }

  .ml-2{
    margin-left: 10px;
  }

  .selecta-all-container{
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .campaign-select{
    /* display: flex; */
    /* align-items: center; */
  }

  .search-section{
    margin-bottom:20px;
  }
  
  .search-section input{
    width: 100%;
    height: 25px;
  }


  `, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,8BAA8B;IAC9B,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":["/* styles.css */\r\nbody {\r\n    font-family: Arial, sans-serif;\r\n    margin: 0;\r\n    padding: 0;\r\n  }\r\n  \r\n  header, footer {\r\n    background-color: #f8f9fa;\r\n    padding: 10px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .main {\r\n    padding: 20px;\r\n  }\r\n  \r\n  .buttons {\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  button {\r\n    margin-right: 10px;\r\n    padding: 10px;\r\n    border: none;\r\n    background-color: #007bff;\r\n    color: white;\r\n    cursor: pointer;\r\n  }\r\n\r\n  .selected-btn{\r\n    background-color: #0056b3;\r\n  }\r\n  \r\n  button:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  \r\n  .table-section {\r\n    margin-top: 20px;\r\n  }\r\n\r\n  .ml-2{\r\n    margin-left: 10px;\r\n  }\r\n\r\n  .selecta-all-container{\r\n    display: flex;\r\n    align-items: center;\r\n    margin-top: 20px;\r\n  }\r\n\r\n  .campaign-select{\r\n    /* display: flex; */\r\n    /* align-items: center; */\r\n  }\r\n\r\n  .search-section{\r\n    margin-bottom:20px;\r\n  }\r\n  \r\n  .search-section input{\r\n    width: 100%;\r\n    height: 25px;\r\n  }\r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
